let route = [
	{
		path: 'auth/permission',//权限
		name: '权限',
		component: () => import('@/view/auth/permission')

	},
	{
		path: 'auth/route',//路由
		name: '路由',
		component: () => import('@/view/auth/route')

	},
	{
		path: 'auth/role',//角色
		name: '角色',
		component: () => import('@/view/auth/role')

	},
	{
		path: 'auth/admin',//管理员管理
		name: '管理员管理',
		component: () => import('@/view/auth/admin')

	},
	{
		path: 'auth/adminActionLog',//管理员操作日志
		name: '管理员管理',
		component: () => import('@/view/auth/adminActionLog')

	},
]
export default route