import { get, post } from '@/unit/request.js'

export default {
    getInfo (data) {
        return get('goods/info', data)
    },
    getInventoryInfo (data) {
        return get('/goods/inventory/info', data)
    },
    getInventoryList (data) {
        return get('/goods/inventory/list', data)
    },
    warehousingPlus (data) {
        return post('/goods/warehousing/plus', data)
    },
    inventoryUpdate (data) {
        return post('/goods/inventory/update', data)
    },
    getList (data) {
        return get('goods/list', data)
    },
    sort (data) {
        return post('/goods/sort', data)
    },
    edit (data) {
        return post('goods/edit', data)
    },
    delete (data) {
        return post('goods/delete', data)
    },
    status (data) {
        return post('goods/status', data)
    },
    topSort (data) {
        return post('goods/top/sort', data)
    },
    getCategory (data) {
        return get('/category/info', data)
    },
    getCategoryList (data) {
        return get('/category/list', data)
    },
    getCategoryTopList (data) {
        return get('/category/top/list', data)
    },
    categoryEdit (data) {
        return post('/category/edit', data)
    },
    categoryDel (id) {
        return post('/category/delete', { id: id })
    },
    categorySort (data) {
        return post('/category/sort', data)
    },
    categoryAttr (id) {
        return get('/category/attr', { category_id: id })
    },
    categoryAttrEdit (data) {
        return post('/category/attr/edit', data)
    },
    categoryAttrDel (id) {
        return post('/category/attr/del', { id: id })
    },
    getBrandList (data) {
        return get('/category/list', data)
    },
}