import { get, post } from '@/unit/request.js'
export default {
    //获取地区受权
    areaAuth (data) {
        return get('admin/area/auth', data)
    },
    //获取地区受权编辑
    areaAuthEdit (data) {
        return post('admin/area/auth/edit', data)
    },
}