import { get, post } from '@/unit/request.js'
export default {
    list (data) {
        return get('/salesman/list', data)
    },
    edit (data) {
        return post('/salesman/edit', data)
    },
    disableEnable (data) {
        return post('/salesman/disable-enable', data)
    },
    delete (data) {
        return post('/salesman/delete', data)
    },
    bindWx (data) {
        return post('/salesman/bindwx', data)
    },
    unBindWx (data) {
        return post('/salesman/unbindwx', data)
    },
    name_find (data) {
        return get('/salesman/name/find', data)
    },
    groupList (data) {
        return get('/salesman/group/list', data)
    },
    groupEdit (data) {
        return post('/salesman/group/edit', data)
    },
    groupDel (data) {
        return post('/salesman/group/del', data)
    }
}