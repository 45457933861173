let route = [
    {
        path: 'config/system',
        name: '系统设置',
        component: () => import('@/view/config/system')
    },
    {
        path: 'config/area',
        name: '地区管理',
        component: () => import('@/view/config/area')
    },
    {
        path: 'config/ad',
        name: '广告管理',
        component: () => import('@/view/config/ad')
    }
]
export default route