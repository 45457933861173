let route = [
	{
		path: '/',//默认首页
		name: 'index',
		meta: {
			title: '总览',
		},
		component: () => import('@/view/index/main')
	},
]
export default route