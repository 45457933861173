let route = [
    {
        path: 'goods/index',
        name: '商品管理',
        component: () => import('@/view/goods/index')
    },
    {
        path: 'goods/create',
        name: '商品发布',
        component: () => import('@/view/goods/create')
    },
    {
        path: 'goods/category',
        name: '商品分类',
        component: () => import('@/view/goods/category')
    },
    {
        path: 'goods/brand',
        name: '品牌管理',
        component: () => import('@/view/goods/brand')
    },
]
export default route