import { get, post } from '@/unit/request.js'
export default {
    list (data) {
        return get('/brand/list', data)
    },
    brandEdit (data) {
        return post('/brand/edit', data)
    },
    brandDel (data) {
        return post('/brand/del', data)
    },
    brandSort (data) {
        return post('/brand/sort', data)
    }
}