//import user from '@/req/api/user.js'
const path = require('path')
let dir = "./api"

const ApiFileList = require.context('./api', true, /\.js$/)
let APIArr = {}
let files = ApiFileList.keys()
//处理动态加载API的文件

files.forEach(paths => {
	let bb = `${path.join(paths)}`
	//the signature '(from:number,length?:number):strging' of'bb.substr' is deprecated.
	let objName = bb.substring(0, (bb.length - 3))
	let content = require(`${dir}/${path.join(paths)}`);
	APIArr[objName] = content.default
})
export default APIArr