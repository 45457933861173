export default {
    install (Vue) {
        //判断是否json数据
        Vue.prototype.isJSON = (str) => {
            try {
                JSON.parse(str)
                return true
            } catch (e) {
                return false
            }
        }
        //文件转base64
        Vue.prototype.getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                let reader = new FileReader()
                let fileResult = ''
                reader.readAsDataURL(file) //开始转
                reader.onload = function () {
                    fileResult = reader.result
                } //转 失败
                reader.onerror = function (error) {
                    reject(error)
                } //转 结束  咱就 resolve 出去
                reader.onloadend = function () {
                    resolve(fileResult)
                }
            })
        }
        // 返回Storage列表id
        Vue.prototype.getStorageIds = (list) => {
            let ids = []
            for (let v of list) {
                ids.push(Number(v.id))
            }
            return ids
        }
        // 过率字符串两边空格/字符
        Vue.prototype.trim = (str, char) => {
            if (char) {
                str = str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '')
            }
            return str.replace(/^\s+|\s+$/g, '')
        }
    }
}