let route = [
    {
        path: 'article/category',
        name: '文章分类',
        component: () => import('@/view/article/category')
    },
    {
        path: 'article/index',
        name: '文章列表',
        component: () => import('@/view/article/index')
    },
]
export default route