import { get, post } from '@/unit/request.js'
export default {
    //获取路由列表
    getRouters () {
        return get('router/list')
    },
    //获取顶级路由列表
    getTopRouter () {
        return get('router/list', { pid: 0 })
    },
    //新增/修改路由
    setRoute (data) {
        return post('router/edit', data)
    },
    //删除路由
    delRoute (id) {
        return post('router/delete', { id: id })
    },
    //获取权限列表
    getPermission () {
        return get('permission/list')
    },
    //获取权限新增/编辑配置
    getPermissionConf () {
        return get('permission/conf')
    },
    //获取顶级权限列表
    getTopPermission () {
        return get('permission/list', { pid: 0 })
    },
    //新增/修改权限
    setPermission (data) {
        return post('permission/edit', data)
    },
    //删除权限
    delPermission (id) {
        return post('permission/delete', { id: id })
    },
    //获取角色列表
    getRole () {
        return get('role/list')
    },
    //角色创建/修改
    setRole (data) {
        return post('role/edit', data)
    },
    //角色删除
    delRole (id) {
        return post('role/delete', { id: id })
    },
    //获取管理员列表
    getAdmin () {
        return get('admin/list')
    },
    //删除管理员
    delAdmin (id) {
        return post('admin/delete', { id: id })
    },
    //管理员操作日志
    adminActionLog (data) {
        return get('/admin/action/log', data)
    }
}