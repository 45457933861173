import { get, post, upload } from '@/unit/request.js'
export default {
    list (data) {
        return get('/storage/list', data)
    },
    uploadFiles (data) {
        return upload('/storage/uploads', data)
    },
    rename (data) {
        return post('/storage/rename', data)
    },
    move (data) {
        return post('/storage/move', data)
    },
    delete (data) {
        return post('/storage/delete', data)
    },
    dir_list (data) {
        return get('/storage/dir/list', data)
    },
    dir_create (data) {
        return post('/storage/dir/create', data)
    },
    dir_rename (data) {
        return post('/storage/dir/rename', data)
    },
    dir_delete (data) {
        return post('/storage/dir/delete', data)
    },
}