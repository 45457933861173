import { get, post, upload } from '@/unit/request.js'

export default {
	getCaptcha () {
		return get('captcha');
	},
	uploadFiles (data) {
		return upload('upload', data);
	},
	uploadAvatar (data) {
		return upload('upload/avatar', data);
	},
	fileRemove (data) {
		return post('files/remove', data);
	},
	getConfig () {
		return get('index/config');
	},
	getArea (data) {
		return get('index/area', data);
	},
	getAreaGroup () {
		return get('/index/area/group/list')
	},
	getAreaTitle (area_id) {
		return get('index/area/title', { area_id });
	},
	areaAameLevel (area_id) {
		return get('index/area/same/level', { area_id });
	},
	getExpress () {
		return get('index/express');
	}
}