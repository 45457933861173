import { get, post } from '@/unit/request.js'
export default {
    getConfig (data) {
        return get('/config/val', { group_name: JSON.stringify(data) })
    },
    setConfig (data) {
        return post('/config/save', data)
    },
    ad () {
        return get('config/ad')
    },
    adEdit (data) {
        return post('config/ad/edit', data)
    },
    areaEdit (data) {
        return post('config/area/edit', data)
    },
    areaDel (data) {
        return post('config/area/del', data)
    }
}