import { get, post } from '@/unit/request.js'

export default {
    getList (data) {
        return get('user/list', data)
    },
    disable (data) {
        return post('user/disable', data)
    },
    find (data) {
        return get('user/find', data)
    },
    findSalesmanId (id) {
        return get('user/find/salesmanId', { user_id: id })
    },
    findShopId (id) {
        return get('user/find/shopId', { user_id: id })
    }
}