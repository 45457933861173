
let route = [
	{
		path: 'tips',//无权限页面
		name: 'tips',
		meta: {
			title: '无权限页面',
		},

		component: () => import('@/view/tipsView')
	},


]


export default route