import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import API from './req/index.js'
import moment from 'moment'
//引入quill-editor编辑器
import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor)
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Print from 'vue-print-nb'
import cnfn from 'chinese-finance-number'
Vue.filter('cnfn', function (n) {
  return cnfn(n)
})
Vue.use(Print)
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import common from './utils/common'//引用
Vue.use(common)//将全局函数当做插件来进行注册
//实现quill-editor编辑器拖拽上传图片
import Quill from 'quill'
import { ImageDrop } from 'quill-image-drop-module'
Quill.register('modules/imageDrop', ImageDrop)

//实现quill-editor编辑器调整图片尺寸
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)

Vue.filter('date', function (n) {
  if (n > 0) {
    return moment(n * 1000).format('YYYY-MM-DD HH:mm:ss') // 转换时间格式
  } else {
    return ''
  }
})
Vue.filter('dateYmd', function (n) {
  if (n > 0) {
    return moment(n * 1000).format('YYYY-MM-DD') // 转换时间格式
  } else {
    return ''
  }
})

Vue.config.productionTip = false
Vue.prototype.$api = API

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})

