import { get, post } from '@/unit/request.js'

export default {
	//登陆
	login (data) {
		return post('admin/login', data)
	},
	//退出登陆
	logout (data) {
		return post('admin/logout', data)
	},
	getUserInfo () {
		return get('admin/profile', {})
	},
	//编辑管理员
	userEdit (data) {
		return post('admin/edit', data)
	},
	//管理员修改信息
	userModify (data) {
		return post('admin/modify', data)
	},
	getTempToken (data) {
		return get('admin/temp/token', data)
	}
}
