let route = [
    {
        path: 'salesman/index',
        name: '业务员管理',
        component: () => import('@/view/salesman/index')

    },
    {
        path: 'salesman/group',
        name: '业务员考核',
        component: () => import('@/view/salesman/group')

    },
]

export default route