import request from "@/unit/index.js";
let qs = require("qs");
export function get(url, data, header = {}) {
  header["Content-Type"] = "application/x-www-form-urlencoded";
  return request({
    url: url,
    method: "get",
    params: data,
    header: header,
  });
}
export function post(url, data, header = {}) {
  header["Content-Type"] = "application/x-www-form-urlencoded";
  return request({
    url: url,
    method: "post",
    data: qs.stringify(data),
    header: header,
  });
}
//文件上传方法
export function upload(url, data, header = {}) {
  header["Content-Type"] = "multipart/form-data";
  return request({
    url: url,
    method: "post",
    data: data,
    header: header,
  });
}
