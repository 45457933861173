import { get, post } from '@/unit/request.js'

export default {
    categoryList () {
        return get('article/category/list');
    },
    categoryEdit (data) {
        return post('article/category/edit', data);
    },
    categoryDel (id) {
        return post('article/category/delete', { id: id });
    },
    list (data) {
        return get('article/list', data);
    },
    edit (data) {
        return post('article/edit', data);
    },
    del (id) {
        return post('article/delete', { id: id });
    },
}